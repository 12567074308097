import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';
import Star from 'shared_components/public/assets/rebrand-icons/star.svg';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import PageLayout from './PageLayout';
import { WError } from 'shared_components/components/WError/WError';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { useApi } from 'shared_components/context';
import { RecordingEarliestParentReleaseEvidenceImagesInner } from 'shared_components/generated/admin';

type ImageKeySignedUrlMap = {
  [key: string]: string;
};

export default function AIEvidence() {
  const { userId, parentRecordingId } = useParams<{
    userId: string;
    parentRecordingId: string;
  }>();
  const location = useLocation();
  const { throwError } = useWError();
  const { adminApi: api } = useApi();

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [location.pathname]);

  const [recordingTitle, setRecordingTitle] = useState<string>();
  const [images, setImages] = useState<
    RecordingEarliestParentReleaseEvidenceImagesInner[]
  >([]);
  const [imageUrls, setImageUrls] = useState<ImageKeySignedUrlMap>({});
  const [carouselIndex, setCarouselIndex] = useState<number>(0);

  const imagesSorted = useMemo<
    RecordingEarliestParentReleaseEvidenceImagesInner[]
  >(() => {
    return images.toSorted(
      (a, b) => Number(b.isPreferred) - Number(a.isPreferred)
    );
  }, [images]);

  const carouselImages = useMemo<
    RecordingEarliestParentReleaseEvidenceImagesInner[]
  >(() => {
    return imagesSorted.slice(carouselIndex, carouselIndex + 2);
  }, [carouselIndex, imagesSorted]);

  const fetchData = async () => {
    if (userId === undefined) return;
    if (parentRecordingId === undefined) return;

    try {
      const { parentRecording, evidenceReleases } = await api.retrieveRecording(
        {
          userId,
          parentRecordingId,
        }
      );

      setRecordingTitle(parentRecording.recordingTitle);

      if (!evidenceReleases) return;

      for (const evidenceRelease of evidenceReleases) {
        for (const image of evidenceRelease.evidenceImages ?? []) {
          setImages((prev) => [...prev, image]);
          api
            .evidenceImageSignedUrlEvidenceImageSignedUrl({
              userId,
              evidenceImageSignedUrlRequest: {
                objectKey: image.objectKey,
              },
            })
            .then(({ signedUrl }) => {
              setImageUrls((prev) => {
                return { ...prev, [image.objectKey]: signedUrl };
              });
            });
        }
      }
    } catch {
      throwError(new WError('Something went wrong'));
    }
  };

  useEffect(() => {
    fetchData();
  }, [parentRecordingId]);

  return (
    <PageLayout>
      <div className="tw-grid tw-grid-rows-[min-content_min-content_min-content_1fr] tw-gap-3 tw-h-full">
        <h2>AI Evidence</h2>

        <div>
          <WButton
            variant="link-secondary"
            icon="chevron-left"
            onClick={() => {
              window.history.back();
            }}
            label="Back"
          />
        </div>

        <h3 className="tw-font-sans tw-text-xl tw-text-white">
          Evidence Results For "{recordingTitle}"
        </h3>

        <div className="tw-grid tw-grid-cols-[1fr_2.6fr_1fr] tw-gap-5 tw-h-full">
          <div className="tw-relative">
            <span className="tw-absolute tw-right-0 tw-top-0 -tw-mx-2.5 tw-h-full tw-border-l tw-border-outlineGraphite" />
            <div className="tw-h-[35rem] tw-bg-surfaceBlack90 tw-rounded-lg tw-pt-6 tw-pr-6 tw-pb-8 tw-pl-6 tw-text-white">
              <h3 className="tw-font-sans tw-text-lg tw-text-white">
                {/* Release Recordings */}
              </h3>
            </div>
          </div>

          <div className="tw-grid tw-gap-5 tw-bg-surfaceBlack90 tw-rounded-lg tw-pt-6 tw-pr-6 tw-pb-8 tw-pl-6 tw-text-white">
            <div className="tw-grid tw-grid-cols-[min-content_1fr_1fr_min-content] tw-gap-1.5">
              <div className="tw-flex tw-items-center">
                <button
                  onClick={() =>
                    setCarouselIndex(
                      carouselIndex - 2 >= 0
                        ? carouselIndex - 2
                        : images.length - 2
                    )
                  }
                >
                  <i className="wi wi-sm wi-chevron-left" />
                </button>
              </div>
              <div className="tw-pr-2">
                <div className="tw-relative tw-aspect-square ">
                  {carouselImages.at(0) && (
                    <img
                      src={imageUrls[carouselImages[0].objectKey]}
                      className="tw-max-h-full"
                    />
                  )}
                  <img
                    src={Star}
                    className={clsx(
                      'tw-absolute tw-top-0 tw-left-0 tw-m-1.5 tw-w-6',
                      {
                        'tw-hidden': !carouselImages.at(0)?.isPreferred,
                      }
                    )}
                  />
                </div>
              </div>
              <div className="tw-pl-2">
                <div className="tw-relative tw-aspect-square">
                  {carouselImages.at(1) && (
                    <img
                      src={imageUrls[carouselImages[1].objectKey]}
                      className="tw-max-h-full"
                    />
                  )}
                  <img
                    src={Star}
                    className={clsx(
                      'tw-absolute tw-top-0 tw-left-0 tw-m-1.5 tw-w-6',
                      {
                        'tw-hidden': !carouselImages.at(1)?.isPreferred,
                      }
                    )}
                  />
                </div>
              </div>
              <div className="tw-flex tw-items-center">
                <button
                  onClick={() =>
                    setCarouselIndex(
                      carouselIndex + 2 < images.length ? carouselIndex + 2 : 0
                    )
                  }
                >
                  <i className="wi wi-sm wi-chevron-right" />
                </button>
              </div>
            </div>

            <div className="tw-grid tw-gap-3">
              <h3 className="tw-font-sans">Evidence Images</h3>
              <div className="tw-grid tw-grid-cols-6 tw-gap-3">
                {imagesSorted
                  .map((image) => (
                    <div
                      key={image.objectKey}
                      className="tw-relative tw-aspect-square"
                    >
                      <img
                        src={imageUrls[image.objectKey]}
                        className="tw-max-h-full"
                      />
                      <img
                        src={Star}
                        className={clsx(
                          'tw-absolute tw-top-0 tw-left-0 tw-m-0.5 tw-w-4',
                          {
                            'tw-hidden': !image.isPreferred,
                          }
                        )}
                      />
                    </div>
                  ))
                  .slice(0, 12)}
              </div>
            </div>
          </div>

          <div>
            <div className="tw-h-[35rem]">
              <div className="tw-h-full tw-flex tw-flex-col tw-gap-5">
                <div className="tw-flex-1 tw-bg-surfaceBlack90 tw-rounded-lg tw-pt-6 tw-pr-6 tw-pb-8 tw-pl-6 tw-text-white">
                  <h3 className="tw-font-sans tw-text-lg tw-text-white">
                    {/* Evidence */}
                  </h3>
                </div>
                <div className="tw-h-[30%] tw-bg-surfaceBlack90 tw-rounded-lg tw-pt-6 tw-pr-6 tw-pb-8 tw-pl-6 tw-text-white">
                  <h3 className="tw-font-sans tw-text-lg tw-text-white">
                    {/* Featured Contributors */}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
