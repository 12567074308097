import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useParams, Link } from 'react-router-dom';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import DataTable from 'react-data-table-component';

import PageLayout from './PageLayout';
import WContainer from 'shared_components/components/WContainer';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { WError } from 'shared_components/components/WError/WError';
import { SocietyDiscographySubmissionModal } from './Components/SocietyDiscographySubmissionModal/SocietyDiscographySubmissionModal';
import AnnotatedEvidenceModal from 'shared_components/components/AnnotatedEvidenceModal';
import WModal from 'shared_components/components/WModal';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { useApi } from 'shared_components/context';
import roleIconMap from 'shared_components/maps/role-icon-map.json';
import {
  PrimaryDiscographyRequestSortOrderEnum,
  RecordingPplContributionsInner,
  RecordingPplContributionsInnerWestburyStatusEnum,
} from 'shared_components/generated/admin';
import { PrimaryDiscographyRequestSortFieldEnum } from 'shared_components/generated/client';

interface DiscographyRow {
  id: string;
  artist: string;
  title: string;
  release: string;
  year?: string | null;
  isrcs: string;
  contributions: string[];
  pplContributions: RecordingPplContributionsInner[];
  thumbnailEvidenceImageKey?: string;
  releaseImageKeys: string[];
}

type RowIdImageUrlMap = {
  [id: string]: string;
};

type PageSortArrayMap = {
  [key: number]: Array<any> | undefined;
};

type SortFieldSortOrderMap = {
  [FIELD in PrimaryDiscographyRequestSortFieldEnum]: PrimaryDiscographyRequestSortOrderEnum | null;
};

type StatusColorMap = {
  [STATUS in RecordingPplContributionsInnerWestburyStatusEnum]: string;
};

const statusColorMap: StatusColorMap = {
  SUBMITTED_TO_SOCIETY: 'tw-text-[#00B3FF]',
  EVIDENCE_NEEDED: 'tw-text-[#FF2600]',
  REGISTERED_WITH_SOCIETY: 'tw-text-[#6EE038]',
  TO_BE_REGISTERED: 'tw-text-textDove',
  OTHER: 'tw-text-textDove',
};

type RouteParams = {
  userId: string;
};

function DiscographyRegistrations() {
  const { userId } = useParams<RouteParams>();
  const { adminApi: api } = useApi();
  const { throwError } = useWError();

  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [rows, setRows] = useState<DiscographyRow[]>([]);
  const [images, setImages] = useState<RowIdImageUrlMap>({});

  const [isPendingExportGeneration, setIsPendingExportGeneration] =
    useState<boolean>(false);

  const [
    isSocietyDiscographySubmissionModalOpen,
    setIsSocietyDiscographySubmissionModalOpen,
  ] = useState<boolean>(false);
  const [isOpenExportConfirmationModal, setIsOpenExportConfirmationModal] =
    useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [recordingsCount, setRecordingsCount] = useState<number>(0);
  const [resetPaginationToggle, setResetPaginationToggle] =
    useState<boolean>(false);

  const handleChangePage = (newPage: number) => {
    if (newPage > page && newPage !== page + 1) {
      skipToLastPage();
      return;
    }
    setPage(newPage);
  };

  const skipToLastPage = async () => {
    if (userId === undefined) {
      return;
    }

    setLoading(true);

    const lastPage = Math.ceil(recordingsCount / rowsPerPage);
    let pageCounter = page + 1;
    let tempPageSortArrayMap = pageSortArrayMap;

    while (true) {
      const discographyData = await api.searchPrimaryDiscography({
        userId,
        primaryDiscographyRequest: {
          size: rowsPerPage,
          sortField,
          sortOrder,
          searchAfter: tempPageSortArrayMap[pageCounter - 1],
        },
      });

      tempPageSortArrayMap = {
        ...tempPageSortArrayMap,
        [pageCounter]: discographyData.sortArray,
      };

      if (pageCounter === lastPage - 1) {
        setPageSortArrayMap({
          ...tempPageSortArrayMap,
          [pageCounter]: discographyData.sortArray,
        });
        setPage(pageCounter);
        break;
      }

      pageCounter++;
    }
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setResetPaginationToggle(true);
  };

  const [sortField, setSortField] =
    useState<PrimaryDiscographyRequestSortFieldEnum>(
      'parentRecording.recordingTitle'
    );
  const [sortOrder, setSortOrder] =
    useState<PrimaryDiscographyRequestSortOrderEnum>('asc');
  const [pageSortArrayMap, setPageSortArrayMap] = useState<PageSortArrayMap>({
    0: undefined,
  });
  const [sortFieldSortOrderMap, setSortFieldSortOrderMap] =
    useState<SortFieldSortOrderMap>({
      'parentRecording.mainArtists': null,
      'parentRecording.recordingTitle': 'asc',
      'earliestParentRelease.releaseTitle': null,
      'earliestParentRelease.dateReleased': null,
      'earliestParentRelease.mediaType': null,
      'earliestParentRelease.format': null,
      'persona.stageName': null,
    });

  const handleSort = (newSortField: PrimaryDiscographyRequestSortFieldEnum) => {
    let newSortOrder: PrimaryDiscographyRequestSortOrderEnum;
    if (newSortField === sortField) {
      newSortOrder =
        sortFieldSortOrderMap[sortField] === 'asc' ? 'desc' : 'asc';
    } else {
      newSortOrder = 'asc';
    }
    setSortFieldSortOrderMap({
      ...sortFieldSortOrderMap,
      [sortField]: null,
      [newSortField]: newSortOrder,
    });
    setSortField(newSortField);
    setSortOrder(newSortOrder);
    setPage(1);
  };

  const ColumnHeading: React.FC<{
    sortField: PrimaryDiscographyRequestSortFieldEnum;
    children: React.ReactNode;
  }> = ({ sortField, children }) => {
    return (
      <button
        onClick={() => handleSort(sortField)}
        className="tw-flex tw-items-center tw-gap-0.5 hover:tw-text-neutral-200"
      >
        {children}
        <SortIcon sortOrder={sortFieldSortOrderMap[sortField]} />
      </button>
    );
  };

  const fetchData = async () => {
    if (userId === undefined) {
      return;
    }

    setLoading(true);

    try {
      const discographyData = await api.searchPrimaryDiscography({
        userId,
        primaryDiscographyRequest: {
          size: rowsPerPage,
          sortField,
          sortOrder,
          searchAfter: pageSortArrayMap[page - 1],
        },
      });

      const rows = discographyData.recordings.map((recording) => {
        const {
          parentRecording,
          earliestParentRelease,
          evidenceReleases,
          thumbnailEvidenceImage,
          westburyContributions,
          pplContributions,
        } = recording;
        return {
          id: parentRecording.id,
          artist: (parentRecording.mainArtists ?? []).join(', '),
          title: parentRecording.recordingTitle,
          release: earliestParentRelease?.releaseTitle ?? '',
          year: earliestParentRelease?.dateReleased,
          isrcs: parentRecording.isrcs?.toString() ?? '',
          contributions: westburyContributions.map(
            (westburyContribution) => westburyContribution.contribution
          ),
          pplContributions: pplContributions ?? [],
          thumbnailEvidenceImageKey: thumbnailEvidenceImage?.objectKey,
          releaseImageKeys: (evidenceReleases ?? []).flatMap((release) =>
            (release.evidenceImages ?? []).map((image) => image.objectKey)
          ),
        };
      });

      setRows(rows);
      setPageSortArrayMap({
        ...pageSortArrayMap,
        [page]: discographyData.sortArray,
      });
      setRecordingsCount(discographyData.count);
    } catch (e) {
      console.error(e);
      setLoadingError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    setResetPaginationToggle(false);
  }, [userId, page, rowsPerPage, sortField, sortOrder]);

  const fetchSignedEvidenceImageUrls = () => {
    if (userId === undefined) {
      return;
    }
    for (const row of rows) {
      if (row.thumbnailEvidenceImageKey === undefined) {
        continue;
      }
      api
        .evidenceImageSignedUrlEvidenceImageSignedUrl({
          userId,
          evidenceImageSignedUrlRequest: {
            objectKey: row.thumbnailEvidenceImageKey,
          },
        })
        .then(({ signedUrl }) => {
          setImages((prev) => {
            return { ...prev, [row.id]: signedUrl };
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  useEffect(() => {
    fetchSignedEvidenceImageUrls();
  }, [rows]);

  const handleGenerateExport = () => {
    if (userId === undefined) {
      return;
    }
    api
      .createGeneratePPLReviewExport({ userId })
      .then(() => {
        setIsOpenExportConfirmationModal(true);
        fetchLatestExportStatus();
      })
      .catch(() => {
        throwError(new WError('PPL review export generation request failed'));
      });
  };

  const fetchLatestExportStatus = async () => {
    if (userId === undefined) {
      return;
    }
    api
      .retrieveLatestPPLReviewExport({ userId })
      .then((pplReviewExport) => {
        setIsPendingExportGeneration(!pplReviewExport.ready);
      })
      .catch(() => {});
  };

  const [isOpenAnnotatedEvidenceModal, setIsOpenAnnotatedEvidenceModal] =
    useState<boolean>(false);
  const [annotatedEvidenceModalUrls, setAnnotatedEvidenceModalUrls] = useState<
    string[]
  >([]);

  const handleOpenAnnotatedEvidenceModal = (releaseImageKeys: string[]) => {
    if (userId === undefined) {
      return;
    }
    for (const imageKey of releaseImageKeys) {
      api
        .evidenceImageSignedUrlEvidenceImageSignedUrl({
          userId,
          evidenceImageSignedUrlRequest: {
            objectKey: imageKey,
          },
        })
        .then(({ signedUrl }) => {
          setAnnotatedEvidenceModalUrls((prev) => {
            return [...prev, signedUrl];
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
    setIsOpenAnnotatedEvidenceModal(true);
  };

  const handleCloseAnnotatedEvidenceModal = () => {
    setAnnotatedEvidenceModalUrls([]);
    setIsOpenAnnotatedEvidenceModal(false);
  };

  const columns = [
    {
      cell: (row: DiscographyRow) => (
        <div className="tw-w-[45px] tw-h-[45px]">
          {row.thumbnailEvidenceImageKey ? (
            <Link to={`/discography/${userId}/ai-evidence/${row.id}`}>
              <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer">
                {images[row.id] ? (
                  <img
                    src={images[row.id]}
                    alt="Evidence"
                    className="tw-w-full tw-h-full"
                  />
                ) : (
                  <div className="tw-flex tw-justify-center">
                    <p className="tw-my-auto tw-animate-ping">⚪</p>
                  </div>
                )}
              </div>
            </Link>
          ) : (
            <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
              <i className="wi wi-shut-eye wi-20px" />
            </div>
          )}
        </div>
      ),
      minWidth: '80px',
      maxWidth: '80px',
    },
    {
      name: (
        <div className="tw-flex tw-flex-col tw-justify-end tw-h-full">
          <ColumnHeading sortField="parentRecording.mainArtists">
            Artist
          </ColumnHeading>
        </div>
      ),
      selector: (row: DiscographyRow) => row.artist,
      minWidth: '200px',
      maxWidth: '200px',
    },
    {
      name: (
        <div className="tw-flex tw-flex-col tw-justify-end tw-h-full">
          <ColumnHeading sortField="parentRecording.recordingTitle">
            Title
          </ColumnHeading>
        </div>
      ),
      selector: (row: DiscographyRow) => row.title,
      minWidth: '200px',
      maxWidth: '200px',
    },
    {
      name: (
        <div className="tw-flex tw-flex-col tw-justify-end tw-h-full">
          <ColumnHeading sortField="earliestParentRelease.releaseTitle">
            Release
          </ColumnHeading>
        </div>
      ),
      selector: (row: DiscographyRow) => row.release,
      minWidth: '210px',
      maxWidth: '210px',
    },
    {
      name: (
        <div className="tw-flex tw-flex-col tw-justify-end tw-h-full">
          <ColumnHeading sortField="earliestParentRelease.dateReleased">
            Year
          </ColumnHeading>
        </div>
      ),
      selector: (row: DiscographyRow) =>
        row.year ? new Date(row.year).getFullYear() : '',
      minWidth: '110px',
      maxWidth: '110px',
    },
    {
      name: (
        <div className="tw-flex tw-flex-col tw-justify-end tw-h-full">
          ISRC(s)
        </div>
      ),
      selector: (row: DiscographyRow) => row.isrcs,
      minWidth: '170px',
      maxWidth: '170px',
    },
    {
      name: (
        <div className="tw-flex tw-flex-col tw-justify-end tw-h-full">
          Contributions
        </div>
      ),
      cell: (row: DiscographyRow) => (
        <div className="tw-flex gap-2">
          {row.contributions.map((westburyContribution, i) => (
            <OverlayTrigger
              key={i}
              delay={{ hide: 350, show: 300 }}
              overlay={(props) => (
                <Tooltip
                  className="black-bg datatable-tip tw-capitalize"
                  {...props}
                >
                  {westburyContribution}
                </Tooltip>
              )}
              placement="top"
            >
              <div className="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-rounded-sm tw-bg-surfaceBlack80">
                <i
                  className={clsx(
                    'wi wi-xs',
                    `wi-role-${roleIconMap[westburyContribution] || 'general'}`
                  )}
                />
              </div>
            </OverlayTrigger>
          ))}
        </div>
      ),
      minWidth: '295px',
      maxWidth: '295px',
    },
    {
      name: (
        <div className="tw-flex tw-flex-col tw-justify-end tw-h-full">
          <div className="tw-uppercase tw-mb-6 tw-text-xs">PPL Discography</div>
          <div>Contributions</div>
        </div>
      ),
      cell: (row: DiscographyRow) => (
        <div className="tw-flex gap-2">
          {row.pplContributions.map(
            ({ westburyContribution, westburyStatus }, i) => (
              <OverlayTrigger
                key={i}
                delay={{ hide: 350, show: 300 }}
                overlay={(props) => (
                  <Tooltip
                    className="black-bg datatable-tip tw-capitalize"
                    {...props}
                  >
                    {westburyContribution}
                  </Tooltip>
                )}
                placement="top"
              >
                <div className="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-rounded-sm tw-bg-surfaceBlack80">
                  <i
                    className={clsx(
                      'wi wi-xs',
                      `wi-role-${
                        roleIconMap[westburyContribution] || 'general'
                      }`,
                      statusColorMap[westburyStatus ?? 'OTHER']
                    )}
                  />
                </div>
              </OverlayTrigger>
            )
          )}
        </div>
      ),
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        borderBottom: 'none !important',
        borderBottomColor: 'none !important',
        fontSize: '14px',
        background: '#151719',
      },
    },
    headCells: {
      style: {
        background: 'transparent',
        color: '#fff',
        paddingBottom: '12px',
        '&:last-of-type': {
          borderLeftStyle: 'solid',
          borderLeftWidth: '1px',
          borderLeftColor: '#535D66',
        },
        '.rdt_TableCol_Sortable span': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        },
        '.rdt_TableCol_Sortable': {
          overflow: 'visible',
        },
      },
    },
    cells: {
      style: {
        background: '#151719',
        color: '#fff',
        fontWeight: '300',
        overflowX: 'auto',
        '&:last-of-type': {
          borderLeftStyle: 'solid',
          borderLeftWidth: '1px',
          borderLeftColor: '#535D66',
        },
      },
    },
    rows: {
      style: {
        fontSize: '14px',
        minHeight: '60px',
        borderBottom: '1px solid #535D66 !important',
      },
    },
    pagination: {
      style: {
        color: '#fff',
        fontSize: '13px',

        backgroundColor: '#151719',
      },
      pageButtonsStyle: {
        color: '#fff',
        fill: '#fff',
        backgroundColor: 'transparent',
        '&:hover:not(:disabled)': {
          color: '#fff',
          fill: '#fff',
          backgroundColor: '#151719',
        },
        '&:active': {
          color: '#fff',
          fill: '#fff',
          backgroundColor: '#151719',
        },
        '&:disabled': {
          cursor: 'unset',
          color: '#535D66',
          fill: '#535D66',
        },
      },
    },
  };

  return (
    <PageLayout>
      {isOpenAnnotatedEvidenceModal && (
        <AnnotatedEvidenceModal
          isOpen={isOpenAnnotatedEvidenceModal}
          onClose={handleCloseAnnotatedEvidenceModal}
          urls={annotatedEvidenceModalUrls}
        />
      )}
      <SocietyDiscographySubmissionModal
        isOpen={isSocietyDiscographySubmissionModalOpen}
        onClose={() => setIsSocietyDiscographySubmissionModalOpen(false)}
        userId={userId!}
      />
      <ExportConfirmationModal
        isOpen={isOpenExportConfirmationModal}
        onClose={() => setIsOpenExportConfirmationModal(false)}
      />
      <div className="tw-flex">
        <h2>Registrations</h2>
      </div>
      <div className="tw-flex tw-mt-6 tw-mb-6">
        <WButton
          variant="link-secondary"
          icon="chevron-left"
          onClick={() => {
            window.history.back();
          }}
          label="Back"
        />
      </div>
      <div className="pageDetailsWrapper">
        <Keys />
        <WContainer>
          <div className="tw-flex tw-flex-col tw-justify-start tw-h-full tw-w-full tw-pl-4">
            <div className="tw-flex tw-flex-col tw-w-full tw-uppercase tw-text-xs tw-border-l tw-border-l-[#FF00A6] tw-pl-2">
              <div>WMC Discography</div>
              <div className="tw-pt-4 tw-pb-8">
                <div className="tw-flex tw-justify-between">
                  <div>
                    <WButton
                      label="Submit to Societies"
                      variant="primary"
                      onClick={() => {
                        setIsSocietyDiscographySubmissionModalOpen(true);
                      }}
                    />
                  </div>
                  <div>
                    <WButton
                      label="Export PPL Review"
                      variant="secondary"
                      icon="export"
                      disabled={isPendingExportGeneration}
                      onClick={handleGenerateExport}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {rows.length > 0 ? (
              <DataTable
                columns={columns}
                data={rows}
                customStyles={customStyles}
                fixedHeader
                fixedHeaderScrollHeight="7000px" // around 100 rows
                pagination
                paginationServer
                paginationTotalRows={recordingsCount}
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[25, 50, 100, 500]}
                paginationResetDefaultPage={resetPaginationToggle}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            ) : (
              <div>
                {loading ? (
                  <div className="tw-flex tw-justify-center">
                    <p className="tw-my-auto tw-animate-ping">⚪</p>
                  </div>
                ) : (
                  <div className="loadingError">
                    <h5>
                      {loadingError
                        ? 'Error loading data'
                        : 'No data available currently'}
                    </h5>
                  </div>
                )}
              </div>
            )}
          </div>
        </WContainer>
      </div>
    </PageLayout>
  );
}

export default DiscographyRegistrations;

const SortIcon: React.FC<{
  sortOrder: PrimaryDiscographyRequestSortOrderEnum | null;
}> = ({ sortOrder }) => {
  if (!sortOrder) {
    return <span className="tw-text-xs">&#8693;</span>;
  }

  return sortOrder === 'asc' ? <span>&#8673;</span> : <span>&#8675;</span>;
};

const Keys: React.FC = () => {
  return (
    <div className="tw-flex tw-justify-end tw-space-x-4 tw-items-center">
      <Key
        color="tw-bg-[#6EE038]"
        label="Registered with society"
        tooltip="This contribution has been registered with the society"
      />
      <Key
        color="tw-bg-[#FF2600]"
        label="Evidence needed"
        tooltip="The society has requested evidence for this contribution"
      />

      <Key
        color="tw-bg-[#00B3FF]"
        label="Submitted to society"
        tooltip="We have submitted this contribution to the society."
      />
      <Key
        color="tw-bg-textDove"
        label="To be registered"
        tooltip="This contribution is pending submission to the society."
      />
    </div>
  );
};

const Key: React.FC<{ color: string; label: string; tooltip: string }> = ({
  color,
  label,
  tooltip,
}) => {
  return (
    <div>
      <OverlayTrigger
        delay={{ hide: 150, show: 100 }}
        overlay={(props) => (
          <Tooltip className="black-bg" {...props}>
            {tooltip}
          </Tooltip>
        )}
        placement="left"
      >
        <span className="pointer-cursor tw-flex tw-text-white tw-leading-1 tw-items-center">
          <span
            className={`tw-rounded-full ${color} tw-mr-2`}
            style={{
              width: '10px',
              height: '10px',
              display: 'block',
            }}
          ></span>
          {label}
        </span>
      </OverlayTrigger>
    </div>
  );
};

interface ExportConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ExportConfirmationModal: React.FC<ExportConfirmationModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <WModal isOpen={isOpen} onClose={onClose} title="" hasCloseButton={true}>
      <div className="tw-pb-8">
        <ThankyouBlock
          title="PPL Review Export In Progress"
          description="Once complete, a download link will appear as a task on your
          dashboard."
        />
      </div>
    </WModal>
  );
};
